import './styles/style.scss';
import printMe from './print';

console.log('testing... 1');

printMe();

const Navbar = {
   data() {
      return {
         isActive: false
      };
   }
};

Vue.createApp(Navbar).mount('#navbar');

const ProgramBanners = {
   data() {
      return {
         state: 'programs'
      };
   }
};

Vue.createApp(ProgramBanners).mount('#program-banners');



const EducationTabs = {
   data() {
      return {
         activeTab: 1
      };
   }
};

Vue.createApp(EducationTabs).mount('#education');